import { FieldConfigs } from "../../interfaces";

const defaultRenderHeader = (
  columnKey: string,
  fieldConfigs: FieldConfigs,
  requestSort: (key: string) => void,
  getSortCaret: (columnField: string, fieldConfigs: FieldConfigs) => JSX.Element | null,
) => {
  const sortField = fieldConfigs[columnKey]?.resourceName

  return (
    <th
      key={Math.random()}
      className="cursor-pointer position-relative table-cell-wrap"
      scope="col"
      onClick={() => requestSort(sortField)}
      title={fieldConfigs[columnKey]?.fieldLabel || columnKey}
    >
      {fieldConfigs[columnKey]?.fieldLabel || columnKey}
      <div className="position-absolute" style={{ right: 0, top: '8px' }}>
        {getSortCaret(columnKey, fieldConfigs)}
      </div>
    </th>
  );
};

export { defaultRenderHeader };