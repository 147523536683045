import React from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { BgsStatus, BgsStatusEnum } from '../utils/enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faFile, faFileAlt, faPaperPlane, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import PortalWrapper from './PortalWrapper';

interface BgsStatusDropdownButtonProps {
    statusBGS: BgsStatus;
    handleBgsStatusUpdate: (status: BgsStatus) => void;
}

const bgsStatusIconMap: { [key: number]: IconDefinition } = {
    0: faBan,
    1: faFile,
    2: faFileAlt,
    3: faPaperPlane,
};

const bgsStatusColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-warning',
    2: 'text-warning',
    3: 'text-success',
};

const getIconByBGSStatus = (status: BgsStatus) => {
    return <FontAwesomeIcon icon={bgsStatusIconMap[Number(status)]} className="me-2" />;
};

const getBgsStatusColor = (status: BgsStatus) => {
    return bgsStatusColorMap[Number(status)];
};

const BgsStatusDropdownButton: React.FC<BgsStatusDropdownButtonProps> = ({
    statusBGS,
    handleBgsStatusUpdate,
}) => {
    return (
        <Dropdown>
            <Dropdown.Toggle
                as={Button}
                variant="secondary"
                id="dropdown-basic"
                className={`btn-soft-primary w-100 ${getBgsStatusColor(statusBGS)}`}
            >
                <span className={getBgsStatusColor(statusBGS)}>
                    {getIconByBGSStatus(statusBGS)}
                    {BgsStatusEnum[statusBGS]}
                </span>
            </Dropdown.Toggle>
            <PortalWrapper>
                <Dropdown.Menu>
                    {Object.entries(BgsStatusEnum)
                        .filter(([statusKey, _]) => Number(statusKey) !== statusBGS)
                        .map(([statusKey, statusValue]) => (
                            <Dropdown.Item
                                key={statusKey}
                                onClick={() => handleBgsStatusUpdate(Number(statusKey) as BgsStatus)}
                                className="d-flex align-items-center"
                            >
                                <FontAwesomeIcon
                                    width={15}
                                    icon={bgsStatusIconMap[Number(statusKey)]}
                                    className={`me-2 ${getBgsStatusColor(Number(statusKey) as BgsStatus)}`}
                                />
                                {statusValue}
                            </Dropdown.Item>
                        ))}
                </Dropdown.Menu>
            </PortalWrapper>
        </Dropdown>
    );
};

export default BgsStatusDropdownButton;
