import {
    memo,
    Fragment,
    useState,
    useEffect,
    useCallback,
} from 'react';
import { Row, Col, Table, Form, Button, } from 'react-bootstrap';
import {
    DealStatusEnum,
    PermissionsEnum,
    getEnumValue,
} from '../../utils/enum';

import Card from '../../components/bootstrap/card';
import { useSortableData } from '../../hooks/useSortableData';
import { useSelection } from '../../hooks/useSelection';
import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ComboButtonGroup, {
    ComboButtonId,
} from '../../components/ComboButtonGroup';
import { Deal, } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import SearchInput from '../../components/SearchInput';
import AddEditDealModal from '../../components/deals/modal/AddEditDealModal';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';

interface DealsResponse {
    page: number;
    itemsPerPage: number;
    amountPages: number;
    amountAllItems: number;
    list: Deal[];
    searchFilters: string[];
}

const comboButtons = [
    { id: 'all', label: 'Alle' },
];

const Deals = memo(() => {
    const navigate = useNavigate();
    const location = useLocation();
    const { companyId = 'oc' } = useParams();
    const { show, message, error, showToast, hideToast } = useToast();
    const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
    const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
    const [availableFilter, setAvailableFilter] = useState<string[]>([]);
    const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
    const [deals, setDeals] = useState<Deal[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(20);
    const [limit, setLimit] = useState<number>(25);
    const [totalEntries, setTotalEntries] = useState<number>(200);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
    const { items: sortedDeals, requestSort } = useSortableData(deals);

    const getId = useCallback((deal: Deal) => deal.id, []);

    const {
        selectedItems: selectedDeals,
        isAllSelected,
        selectedCount,
        handleSelectAll,
        handleDeSelectAll,
        handleSelectRow,
    } = useSelection(deals, getId);

    const handleSearch = (data: { query: string, filter: string | undefined }) => {
        if (data.query) {
            setSearchQuery(data.query);
            setSelectedSearchFilter(data.filter || '');
            setCurrentPage(1);
        }
    };

    const fetchDeals = useCallback(async () => {
        setDeals([]);
        setIsLoading(true);
        let queryParams = `?page=${currentPage}`;

        if (selectedCombo !== 'all') {
            queryParams += `&status=${selectedCombo}`;

        } else {
            if (searchQuery) {
                if (searchQuery) {
                    queryParams += `&search=${encodeURIComponent(searchQuery)}`;
                    if (selectedSearchFilter !== 'all') {
                        queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
                    }
                }
            }
        }

        try {
            const response = await ApiClient.get(`/deals${queryParams}`);
            const dealsResponse = response.data as DealsResponse;
            setTotalPages(dealsResponse.amountPages);
            setDeals(dealsResponse.list);
            setCurrentPage(dealsResponse.page);
            setLimit(dealsResponse.itemsPerPage);
            setTotalEntries(dealsResponse.amountAllItems);
            setAvailableFilter(dealsResponse.searchFilters)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }, [currentPage, selectedCombo, searchQuery]);


    useEffect(() => {
        if (permissionsLoaded) {
            const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewDeals, 'read');

            if (hasPermission) {
                fetchDeals();
            } else {
                navigate('/errors/error404');
            }
        }
    }, [
        currentPage,
        searchQuery,
        selectedCombo,
        fetchDeals,
        permissionsLoaded,
        location,
    ]);


    const handleDealUpdateSubmit = () => {
        fetchDeals();
    };

    const handleComboSelect = (newCombo: ComboButtonId) => {
        setSelectedCombo(newCombo);
        setCurrentPage(1);
    };

    const resetSearch = () => {
        setSearchQuery('');
        setResetSearchInput(true);
        setCurrentPage(1);
    };

    useEffect(() => {
        if (resetSearchInput) {
            setResetSearchInput(false);
        }
    }, [resetSearchInput]);


    useEffect(() => {
        resetSearch();
    }, [location]);

    return (
        <Fragment>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
                <h3>Deals</h3>
                <AddEditDealModal
                    buttonName="Deal hinzufügen"
                    modalTitle={`Deal hinzufügen`}
                    onSubmitSuccess={handleDealUpdateSubmit}
                >
                </AddEditDealModal>
            </div>
            <Card className="card-block card-stretch card-height">
                <Card.Body>
                    <Row className="d-flex justify-content-between mb-4">
                        <Col md={6}>
                            {searchQuery ?
                                <div className="d-flex align-items-baseline">
                                    <h4 className="m-0">Suchergebnisse</h4>
                                    <span className="ms-3 d-flex align-items-baseline">
                                        <Button
                                            className="m-0 p-0 fs-6"
                                            variant="link"
                                            onClick={resetSearch}
                                        >
                                            Suche beenden
                                        </Button>
                                    </span>
                                </div>

                                : <ComboButtonGroup
                                    buttons={comboButtons}
                                    selectedCombo={selectedCombo}
                                    setSelectedCombo={setSelectedCombo}
                                    borderRadius="normal"
                                ></ComboButtonGroup>}
                        </Col>
                        <Col md={3}>
                            <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <div style={{ overflowX: 'auto' }}>
                <Table responsive="md" size="sm">
                    <thead>
                        <tr>
                            <th
                                className="d-flex align-items-center"
                                style={{ cursor: 'pointer' }}
                                scope="col"
                                onClick={() => requestSort('title')}
                            >
                                <Form.Check
                                    disabled={!userHasPermissionByRight(PermissionsEnum.ViewDeals, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewDeals, 'delete')}
                                    className="me-3"
                                    type="checkbox"
                                    checked={isAllSelected}
                                    onChange={handleSelectAll}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                />
                                Title
                            </th>
                            <th
                                style={{ cursor: 'pointer', width: '80px' }}
                                scope="col"
                                onClick={() => requestSort('name')}
                            >
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedCount > 0 && (
                            <GeneralSelectionActions
                                selectedItems={selectedDeals}
                                selectedCount={selectedCount}
                                handleDeSelectAll={handleDeSelectAll}
                                onSubmitSuccess={handleDealUpdateSubmit}
                                amountAllItems={totalEntries}
                                entityType='deals'
                            ></GeneralSelectionActions>
                        )}
                        {isLoading
                            ? Array.from({ length: 8 }).map((_, index) => (
                                <SkeletonRow key={index} columnCount={5} />
                            ))
                            : sortedDeals.map((deal) => (
                                <tr key={deal.id} className="bg-white">
                                    <td className="d-flex align-items-center">
                                        <Form.Check
                                            disabled={!userHasPermissionByRight(PermissionsEnum.ViewDeals, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewDeals, 'delete')}
                                            className="me-3"
                                            type="checkbox"
                                            checked={
                                                selectedDeals[deal.id] ?? false
                                            }
                                            onChange={() => { }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleSelectRow(deal.id, e)
                                            }}
                                        />
                                        <Link
                                            to={`/${companyId}/deals/${deal.id}`}
                                            className="btn btn-link ps-0 text-start"
                                            style={{ overflowWrap: 'break-word', flex: 1 }}
                                        >
                                            {deal.title}
                                        </Link>
                                    </td>
                                    <td>{getEnumValue(DealStatusEnum, deal.status.toString())}</td>

                                </tr>
                            ))}
                    </tbody>
                </Table>
                {!isLoading && sortedDeals.length === 0 && (
                    <div
                        className="d-flex justify-content-center align-items-center border rounded my-3"
                        style={{ height: '50px' }}
                    >
                        <p className="p-0 m-0">Keine Deals gefunden</p>
                    </div>
                )}
            </div>

            {sortedDeals.length > 0 && (
                <Row>
                    <Col>
                        <PaginationInfo
                            currentPage={currentPage}
                            limit={limit}
                            totalEntries={totalEntries}
                            onLimitChange={(size) => {
                                setLimit(size);
                                setCurrentPage(1);
                            }}
                        />
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <DynamicPagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </Col>
                </Row>
            )}

            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </Fragment>
    );
});

export default Deals;
