import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PortalWrapper from '../PortalWrapper';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { de } from 'date-fns/locale';

registerLocale('de', de);

interface DateRangeDropdownFilterProps {
    onDateRangeChange: (startDate: Date | null, endDate: Date | null) => void;
    isDisabled?: boolean;
    titlePlaceholder?: string;
    initialStartDate?: Date | null;
    initialEndDate?: Date | null;
}

// This component provides a dropdown for selecting a date range (start and end date) using date pickers.
// It displays the selected range in the button and allows clearing/resetting of the dates. 
// The component ensures that the start date cannot be later than the end date. 
// The selected range is passed to the parent via `onDateRangeChange`. 
// German locale is used for date formatting, and the dropdown can be disabled with `isDisabled`.
const DateRangeDropdownFilter: React.FC<DateRangeDropdownFilterProps> = ({
    onDateRangeChange,
    isDisabled,
    titlePlaceholder = 'Zeitraum',
    initialStartDate = null,
    initialEndDate = null,
}) => {
    const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
    const [endDate, setEndDate] = useState<Date | null>(initialEndDate);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        setStartDate(initialStartDate);
        setEndDate(initialEndDate);
    }, [initialStartDate, initialEndDate]);

    const handleReset = (event: React.MouseEvent) => {
        event.stopPropagation();
        setStartDate(null);
        setEndDate(null);
        onDateRangeChange(null, null);
    };

    const handleApply = () => {
        onDateRangeChange(startDate, endDate);
        setDropdownOpen(false);
    };

    const handleStartDateChange = (date: Date | null) => {
        if (date && endDate && date > endDate) {
            setEndDate(null);
        }
        setStartDate(date);
    };

    const handleEndDateChange = (date: Date | null) => {
        if (date && startDate && date < startDate) {
            setStartDate(null);
        }
        setEndDate(date);
    };

    const formatDateString = (date: Date | null) => {
        return date instanceof Date ? date.toLocaleDateString('de-DE') : '';
    };

    const title = startDate
        ? endDate
            ? `${formatDateString(startDate)} - ${formatDateString(endDate)}`
            : `${formatDateString(startDate)}`
        : titlePlaceholder;

    return (
        <Dropdown className='' show={dropdownOpen} onToggle={setDropdownOpen}>
            <Dropdown.Toggle
                disabled={isDisabled}
                variant="outline-primary"
                id="dropdown-date-range"
                className="custom-dropdown-focus me-2"
                onClick={() => setDropdownOpen(!dropdownOpen)}
            >
                {startDate && (
                    <FontAwesomeIcon icon={faTimes} className="me-2 cursor-pointer" onClick={handleReset} />
                )}
                {title}
            </Dropdown.Toggle>
            <PortalWrapper>
                <Dropdown.Menu>
                    <div className="px-3 py-2">
                        <div className="d-flex flex-column">
                            <label>Startdatum</label>
                            <DatePicker
                                selected={startDate || undefined}
                                onChange={handleStartDateChange}
                                selectsStart
                                startDate={startDate || undefined}
                                endDate={endDate || undefined}
                                isClearable
                                placeholderText="Startdatum auswählen"
                                className="form-control"
                                locale="de"
                                dateFormat="dd.MM.yyyy"
                                showWeekNumbers
                                weekStartsOn={1}
                            />
                        </div>
                        <div className="d-flex flex-column mt-3">
                            <label>Enddatum</label>
                            <DatePicker
                                selected={endDate || undefined}
                                onChange={handleEndDateChange}
                                selectsEnd
                                startDate={startDate || undefined}
                                endDate={endDate || undefined}
                                minDate={startDate || undefined}
                                isClearable
                                placeholderText="Enddatum auswählen"
                                className="form-control"
                                locale="de"
                                dateFormat="dd.MM.yyyy"
                                showWeekNumbers
                                weekStartsOn={1}
                            />
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <Button size="sm" className="btn btn-soft-primary m-2" onClick={handleApply} disabled={!startDate || !endDate}>
                                Anwenden
                            </Button>
                        </div>
                    </div>
                </Dropdown.Menu>
            </PortalWrapper>
        </Dropdown>
    );
};

export default DateRangeDropdownFilter;
