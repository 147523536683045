import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal, ListGroup, Button, Row, Col, Spinner } from 'react-bootstrap';
import { MediaItem } from '../interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faX, faUpload, faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import ImageEditor, { ImageEditorRef } from './ImageEditor';
import { getMediaUrl } from '../utils/media/utils';
import FileDropzone from './media/FileDropZone';
import { ApiClient } from '../services/ApiClient';
import { useToast } from '../services/context/ToastContext';
import BgsStatusDropdownButton from './BgsStatusDropdownButton';

interface BGSViewerModalProps {
    show: boolean;
    handleClose: () => void;
    media: MediaItem[];
    title?: string;
    onSubmitSuccess: () => void;
}

const BGSViewerModal: React.FC<BGSViewerModalProps> = ({
    show,
    handleClose,
    media,
    title,
    onSubmitSuccess
}) => {
    const blankoMedia = useMemo(() => {
        return media.find(item => item.title === 'bgs-blanko.png') ?? null;
    }, [media]);

    const filledMedia = useMemo(() => {
        return blankoMedia ? { ...blankoMedia, id: blankoMedia.id + 1, title: 'bgs-filled.png' } : null;
    }, [blankoMedia]);

    useEffect(() => {
        setSelectedMedia(filledMedia ?? null);
    }, [blankoMedia]);

    const filledBGS = media.find((item: { title: string; }) => item.title === 'bgs-filled.png')

    const [selectedMedia, setSelectedMedia] = useState<MediaItem | null>(blankoMedia ?? null);
    const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formChanged, setFormChanged] = useState<boolean>(false);

    const { showToast } = useToast();
    const imageEditorRef = useRef<ImageEditorRef>(null);

    const handleSaveData = async () => {
        setIsLoading(true)
        if (imageEditorRef.current) {
            const file = await imageEditorRef.current.createFile();
            // Here remove the old file with useCase filled from the media and upload a new file with usecase filled
            if (file) {
                const filledBgs: MediaItem | null = media.find((item: { title: string; }) => item.title === 'bgs-filled.png') ?? null;
                if (filledBgs) {
                    await ApiClient.delete(`/media/${filledBgs.id}`);
                }
                imageEditorRef.current.saveData();
                await handleFilesChange([file], '', 0)
            }
        }
        setIsLoading(false)
        setIsInEditMode(false)
    };

    const resetFormData = () => {
        if (imageEditorRef.current) {
            imageEditorRef.current.resetFormValues();
        }

        setIsInEditMode(true)
    }

    const loadInitialData = () => {
        if (imageEditorRef.current) {
            imageEditorRef.current.loadInitialData();
        }
    }

    const handleMediaSelect = (mediaItem: MediaItem) => {
        setSelectedMedia(mediaItem);
        setIsInEditMode(false)
    };

    const handleFormChange = (hasChanged: boolean) => {
        setFormChanged(hasChanged)
    }

    const isNoBgsAvailable = !blankoMedia;

    const handleFilesChange = async (newFiles: File[], convention: string, folderId: number) => {
        if (!newFiles.length) {
            return
        }
        setIsLoading(true)
        try {
            for (let index = 0; index < newFiles.length; index++) {
                const file = newFiles[index];
                const formData = new FormData();
                formData.append('file', file);
                convention && formData.append('convention', convention)
                formData.append('folderId', folderId?.toString());
                //formData.append('useCase', 'blanko');
                await ApiClient.post('/upload', formData);
            }
            setSelectedMedia(filledMedia)
            showToast(`Erfolgreich gespeichert`, false);
            onSubmitSuccess()
        } catch (error) {
            showToast('Fehler beim Speichern', true);
            console.error('Error during file upload:', error);
        } finally {
            setIsLoading(false)
        }
    };

    const replaceDocument = async () => {
        // here remove blanko BGS media so that a new blanko can be uploaded
        try {
            setIsLoading(true);
            await ApiClient.delete(`/media/${blankoMedia?.id}`);
            onSubmitSuccess()
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleBgsStatusUpdate = () => {

    }

    return (
        <Modal show={show} onHide={handleClose} keyboard={false} fullscreen>
            <Modal.Header className="d-flex flex-column flex-lg-row align-items-start justify-content-lg-between align-items-lg-center">
                <div className='d-flex flex-column flex-lg-row'>
                    <div className='d-flex align-items-center mb-3 mb-lg-0' style={{ minWidth: '250px' }}>
                        <Button
                            variant="secondary"
                            className="btn-soft-primary round-modal-close-button me-2"
                            style={{ padding: 0 }}
                            onClick={handleClose}
                        >
                            <FontAwesomeIcon icon={faX} size='sm' />
                        </Button>

                        <div><h5 className='mb-0'>{title}</h5>
                            <p className={`p-0 m-0 text-gray`}>XXX-XXXX-XXXX</p>
                        </div>
                    </div>

                    <div className='vertical-line'></div>

                    {isNoBgsAvailable ? <div className='ps-lg-5 mb-3 mb-lg-0'>
                        {<h5 className='mb-0'>Original</h5>}
                        <p className={`p-0 m-0 text-gray`}>Noch nicht vorhanden</p>
                    </div> : <div className='ps-lg-5 mb-3 mb-lg-0'>
                        {selectedMedia?.id === filledMedia?.id &&
                            <>
                                <h5 className='mb-0'>Kopie zum Bearbeiten</h5>
                                <p className={`p-0 m-0 text-gray`}>{filledBGS ? '12.12.2023 um 10:02 Uhr' : '-'}</p>
                            </>
                        }
                        {selectedMedia?.id === blankoMedia?.id &&
                            <>
                                <h5 className='mb-0'>Original</h5>
                                <p className={`p-0 m-0 text-gray`}>12.12.2023 um 10:02 Uhr</p>
                            </>
                        }

                    </div>}

                </div>
                <div>
                    {selectedMedia?.title === 'bgs-blanko.png' && <>
                        <Button
                            variant="secondary"
                            className="btn-soft-primary me-2"
                            onClick={replaceDocument}
                        >
                            Dokument ersetzen <FontAwesomeIcon icon={faUpload} size='sm' />
                        </Button>

                        <a href={blankoMedia ? getMediaUrl(blankoMedia) : ''} target="_blank" rel="noopener noreferrer">
                            <Button
                                variant="secondary"
                                className="btn-soft-primary me-2"
                            >
                                Herunterladen <FontAwesomeIcon icon={faDownload} size='sm' />
                            </Button>
                        </a>
                    </>
                    }

                    {selectedMedia?.title === 'bgs-filled.png' && (
                        !isInEditMode ? (
                            <>
                                {
                                    media.find((item: { title: string; }) => item.title === 'bgs-filled.png') && <>

                                        <Button
                                            variant="secondary"
                                            className="btn-soft-primary me-2 mb-2"
                                            onClick={resetFormData}
                                        >
                                            Original wiederherstellen  <FontAwesomeIcon icon={faArrowsRotate} size='sm' />
                                        </Button>


                                        <a href={filledBGS ? getMediaUrl(filledBGS) : ''} target="_blank" rel="noopener noreferrer">
                                            <Button
                                                variant="secondary"
                                                className="btn-soft-primary me-2 mb-2"
                                            >
                                                Herunterladen <FontAwesomeIcon icon={faDownload} size='sm' />
                                            </Button>
                                        </a>
                                    </>
                                }
                                <Button
                                    variant="primary"
                                    className="me-2"
                                    onClick={() => setIsInEditMode(true)}
                                >
                                    Bearbeiten
                                </Button>
                            </>
                        ) : (
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="d-flex flex-column me-5">
                                    <h5 className="m-0">Bearbeiten</h5>
                                    <span style={{ width: '150px' }}>
                                        {formChanged ? (
                                            <span className="text-danger">Änderungen</span>
                                        ) : (
                                            <span>Keine Änderungen</span>
                                        )}
                                    </span>
                                </div>
                                <Button
                                    variant="secondary"
                                    className="btn-soft-primary me-2"
                                    onClick={() => {
                                        loadInitialData();
                                        setIsInEditMode(false);
                                    }}
                                >
                                    Abbrechen
                                </Button>

                                <Button
                                    variant="primary"
                                    className="me-2"
                                    onClick={handleSaveData}
                                    disabled={isLoading}
                                >
                                    Speichern
                                    {isLoading && (
                                        <Spinner
                                            className="ms-2"
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></Spinner>
                                    )}
                                </Button>
                            </div>
                        )
                    )}
                </div>
            </Modal.Header>
            <Modal.Body className="py-0">
                <Row className='h-100'>
                    <Col lg={2}>
                        <div className='my-4'>
                            <BgsStatusDropdownButton statusBGS={3} handleBgsStatusUpdate={handleBgsStatusUpdate}></BgsStatusDropdownButton>
                        </div>

                        <div className='horizontal-line'></div>

                        <div className="media-list mt-5" style={{ minWidth: '250px', overflowY: 'auto' }}>
                            <ListGroup>
                                {isNoBgsAvailable ? (
                                    <ListGroup.Item
                                        className='py-3 no-border rounded'
                                        key={0}
                                        active={true}
                                        action
                                    >
                                        Original
                                        <p className={`p-0 m-0 text-gray`}>Noch nicht vorhanden</p>
                                    </ListGroup.Item>
                                ) : (
                                    <>
                                        {filledMedia && (
                                            <ListGroup.Item
                                                className={`py-3 no-border rounded ${selectedMedia?.id === filledMedia?.id ? '' : 'text-gray'}`}
                                                key={filledMedia.id}
                                                active={selectedMedia?.id === filledMedia.id}
                                                action
                                                onClick={() => handleMediaSelect(filledMedia)}
                                            >
                                                Kopie zum Bearbeiten
                                                <p className={`p-0 m-0 text-gray`}>{filledBGS ? '12.12.2023 um 10:02 Uhr' : '-'} </p>
                                            </ListGroup.Item>
                                        )}

                                        {blankoMedia && (
                                            <ListGroup.Item
                                                className={`py-3 no-border rounded ${selectedMedia?.id === blankoMedia?.id ? '' : 'text-gray'}`}
                                                key={blankoMedia?.id}
                                                active={selectedMedia?.id === blankoMedia?.id}
                                                action
                                                onClick={() => handleMediaSelect(blankoMedia!)}
                                            >
                                                Original
                                                <p className={`p-0 m-0 text-gray`}>12.12.2023 um 10:02 Uhr</p>
                                            </ListGroup.Item>
                                        )}
                                    </>
                                )}
                            </ListGroup>
                        </div></Col>
                    <Col lg={10} className='h-100 p-0' style={{ zIndex: 10 }} >
                        {selectedMedia && (
                            selectedMedia.title === 'bgs-blanko.png' ? (
                                <div style={{ height: '100%', overflowY: 'auto' }} className="media-preview">
                                    {selectedMedia.mediaType === 'image' && (
                                        <img src={getMediaUrl(selectedMedia)} alt={selectedMedia.title} className="center-image" />
                                    )}
                                </div>
                            ) : (
                                <ImageEditor initialFormValues={localStorage.getItem('formValues') ? JSON.parse(localStorage.getItem('formValues') ?? '{}') : null} ref={imageEditorRef} imageUrl={getMediaUrl(blankoMedia!)} inEditMode={isInEditMode} onFormChange={handleFormChange} />
                            )
                        )}

                        {!selectedMedia && <div className='d-flex justify-content-center align-items-center h-100 w-100 p-5'><FileDropzone onFilesChange={(files) => handleFilesChange(files, '', 0)}></FileDropzone></div>}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default BGSViewerModal;
