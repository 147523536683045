import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendar, faLaptop, } from '@fortawesome/pro-regular-svg-icons';
import ProgressBarComponent from '../ProgressBarComponent';
import { BgsStatus, StudyStatus, TimeModelEnum, WvStatus, getEnumValue } from '../../utils/enum';
import { calculateProgressFromStartAndEndDate } from '../../utils/utils';
import StudyStatusIndicator from '../StudyStatusIndicator';
import WvStatusIndicator from '../WvStatusIndicator';
import BgsStatusIndicator from '../BgsStatusIndicator';

interface StatusDisplayProps {
    status?: string;
    bgsStatus: number;
    wvStatus: number;
    studyStatus: number
    timeModel: string;
    startDate: string;
    endDate: string;
    device: string;
}

const StatusDisplay: React.FC<StatusDisplayProps> = ({ status, wvStatus, bgsStatus, timeModel, startDate, endDate, device, studyStatus }) => {
    const progress = calculateProgressFromStartAndEndDate(startDate, endDate);

    return (
        <div className="d-flex flex-column flex-lg-row align-items-lg-center text-nowrap">
            <BgsStatusIndicator bgsStatus={bgsStatus as BgsStatus} />
            <WvStatusIndicator wvStatus={wvStatus as WvStatus} />
            <StudyStatusIndicator studyStatus={studyStatus as StudyStatus} />

            {timeModel && <div className="d-flex align-items-center me-md-4 mb-2 mb-md-0">
                <FontAwesomeIcon icon={faClock} className="me-2 text-muted" />
                <span className="text-muted">{getEnumValue(TimeModelEnum, timeModel)}</span>
            </div>}

            <div className="d-flex flex-column flex-md-row align-items-lg-center me-md-4 mb-2 mb-md-0">
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faCalendar} className="me-2 text-muted" />
                    <span className="text-muted">Start: {startDate}</span>
                </div>
                <div className="d-flex align-items-center my-2 my-md-0">
                    <span style={{ width: '100px' }} className='mx-2'>
                        <ProgressBarComponent now={progress} label='' />
                    </span>
                </div>
                <div className="d-flex align-items-center">
                    <span className="text-muted">Ende: {endDate}</span>
                </div>
            </div>

            {device && <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faLaptop} className="me-2 text-muted" />
                <span className="text-muted">{device}</span>
            </div>}

        </div>
    );
}
export default StatusDisplay;
