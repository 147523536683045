import { Button, Col, Row } from 'react-bootstrap';
import { Deal, IGlobalMeasureDetails, MediaItem, Participant } from '../../../interfaces';
import Card from '../../bootstrap/card';
import GenericInfosCard from '../../GenericInfosCard';
import { AbortReasonOptions, PSM, SelfPay, TimeModelEnum, YesNoToJaNein, getEnumValue } from '../../../utils/enum';
import { calculateProgressFromStartAndEndDate, formatDateWithoutTime, formatEuro } from '../../../utils/utils';
import ProgressBarComponent from '../../ProgressBarComponent';
import DealBadge from '../../DealBadge';
import AddEditDealModal from '../../deals/modal/AddEditDealModal';
import ModuleList from '../../ModuleList';
import BgsStatusDropdownButton from '../../BgsStatusDropdownButton';
import DealManagement from '../../DealManagement';
import { useEffect, useState } from 'react';
import BGSViewerModal from '../../BGSViewerModal';
import { ApiClient } from '../../../services/ApiClient';
import WvViewerModal from '../../WvViewerModal';
import GenerateWvModal from '../../GenerateWvModal';

interface DealInfosProps {
    isLoading?: boolean;
    deal?: Deal;
    participant?: Participant
    label: string;
    onSubmitSuccess: (dealId?: number) => void;
}

const DealInfos: React.FC<DealInfosProps> = ({ deal, participant, label, isLoading, onSubmitSuccess }) => {

    const [showBgsViewerModal, setShowBgsViewerModal] = useState<boolean>(false);
    const [showWvViewerModal, setShowWvViewerModal] = useState<boolean>(false);
    const [emailTemplateSettings, setEmailTemplateSettings] = useState<{ title: string, body: string }>();
    const [globalMeasureDetails, setGlobalMeasureDetails] = useState<IGlobalMeasureDetails>();

    //Temporary
    const [media, setMedia] = useState<MediaItem[]>();

    useEffect(() => {
        const getMedia = async () => {
            const res = await ApiClient.get(`/media`);
            setMedia(res.data.list)
        }

        const getTemplateDetails = async () => {
            const res = await ApiClient.get('/settings?ident=contractEmailTemplate')
            setEmailTemplateSettings(JSON.parse(res.data.list[0].details))
        }

        const getMeasureDetails = async () => {
            const res = await ApiClient.get('/settings?ident=globalMeasureDetails')
            setGlobalMeasureDetails(JSON.parse(res.data.list[0].details))
        }
        getMedia()
        getTemplateDetails()
        getMeasureDetails()
    }, [onSubmitSuccess]);

    function getGeneralInfo() {
        const generalInfo = [
            {
                label: 'Titel',
                value: deal?.title || '-',
            },
            {
                label: 'Maßnahmen-Nr.',
                value: deal?.actionNumber.toString() || '-',
            },
            {
                label: 'MacBook',
                value: getEnumValue(YesNoToJaNein, deal?.macbook.toString()),
            },
            {
                label: 'Datum (Angebot)',
                value: deal?.offerDate ? formatDateWithoutTime(deal?.offerDate) : '-',
            },
            {
                label: 'Nummer',
                value: deal?.offerNumber || '-',
            },
            {
                label: 'Setter Name',
                value: deal?.setterName || '-',
            },
            {
                label: 'Closer Name',
                value: deal?.closerName || '-',
            },
        ];
        return generalInfo;
    }

    function getFinanceInfo() {
        const financeInfo = [
            {
                label: 'Preis',
                value: `${deal?.priceTotalGross ? formatEuro(deal?.priceTotalGross) + ' (Brutto)' : '-'} <br> ${deal?.priceTotalNet ? formatEuro(deal?.priceTotalNet) + ' (Netto)' : '-'}`,
                isMultiline: true
            },
            {
                label: 'Preis, Steuern',
                value: deal?.priceTax ? formatEuro(deal?.priceTax) : '-',
            },
            {
                label: 'Weiteres',
                value: `${deal?.selfPay.toString() && getEnumValue(SelfPay, deal?.selfPay.toString()) + '<br>'} ${deal?.psmIncludet.toString() && getEnumValue(PSM, deal?.psmIncludet.toString())}`,
                isMultiline: true
            }
        ];
        return financeInfo;
    }

    function getAbortInfo() {
        const abortInfo = [
            {
                label: 'Deal abgebrochen?',
                value: getEnumValue(YesNoToJaNein, deal?.abort.toString()),
            },
            {
                label: 'Datum des Abbruchs',
                value: deal?.abortDate ? formatDateWithoutTime(deal?.abortDate) : '-',
            },
            {
                label: 'Grund des Abbruchs',
                value: getEnumValue(AbortReasonOptions, deal?.abortReason.toString()),
            },
            {
                label: 'Weitere Notizen / Hinweise zum Abbruch',
                value: deal?.abortNotice.toString() || '-',
            },
        ];
        return abortInfo;
    }

    const progress = deal?.start && deal?.end && calculateProgressFromStartAndEndDate(formatDateWithoutTime(deal?.start), formatDateWithoutTime(deal?.end));

    const handleWvStatusUpdate = () => {

    }

    const handleBgsStatusUpdate = () => {

    }

    const handleBgsViewerModalOpen = () => {
        setShowBgsViewerModal(true);
    };

    const handleBgsViewerModalClose = () => {
        setShowBgsViewerModal(false);
    };

    const handleWvViewerModalOpen = () => {
        setShowWvViewerModal(true);
    };

    const handleWvViewerModalClose = () => {
        setShowWvViewerModal(false);
    };


    return (
        <>
            {media && <BGSViewerModal
                onSubmitSuccess={onSubmitSuccess}
                show={showBgsViewerModal}
                handleClose={handleBgsViewerModalClose}
                media={media}
                title="Bildungsgutschein"
            />}

            {media && <WvViewerModal
                show={showWvViewerModal}
                handleClose={() => handleWvViewerModalClose()}
                media={media}
                onSubmitSuccess={onSubmitSuccess}
                title={'Weiterbildungsvertrag'}
            />
            }

            <Card>
                <Card.Body>
                    <Card.Header>
                        <div>
                            <div className='d-flex align-items-center'>
                                <h4 className='mb-0'>{label}</h4> {!label.includes('Aktuell') && deal && <DealBadge status={deal?.status}></DealBadge>}
                            </div>
                            <p>{deal?.title}</p>
                        </div>
                        <div>
                            {deal && <AddEditDealModal
                                buttonName="Bearbeiten"
                                modalTitle={`${deal.title} bearbeiten`}
                                deal={deal}
                                onSubmitSuccess={onSubmitSuccess}
                            >
                            </AddEditDealModal>}
                        </div>
                    </Card.Header>

                    <hr />
                    <Row>
                        <Col lg={6}>
                            <Card className={`card-block card-stretch card-height`}>
                                <Card.Body>
                                    <h5 className="mb-3">BGS & WV</h5>
                                    <Row className="">
                                        <Col lg={4} >
                                            <p className="font-weight-bold text-black">Bildungsgutschein</p>
                                        </Col>
                                        <Col >{deal?.bgsnumber}</Col>
                                    </Row>
                                    <div className='d-flex mb-5'>
                                        <Button className={`btn-soft-primary me-5`} onClick={handleBgsViewerModalOpen}>
                                            BGS öffnen und bearbeiten
                                        </Button>
                                        <BgsStatusDropdownButton statusBGS={3} handleBgsStatusUpdate={handleBgsStatusUpdate}></BgsStatusDropdownButton>
                                    </div>

                                    <Row className="">
                                        <Col lg={4} >
                                            <p className="font-weight-bold text-black">Weiterbildungsvertrag</p>
                                        </Col>
                                        <Col >{deal?.actionNumber} (TBD)</Col>
                                    </Row>
                                    <div className='d-flex'>
                                        {
                                            deal && emailTemplateSettings && globalMeasureDetails && <GenerateWvModal
                                                modalTitle='WV erstellen'
                                                buttonName='Erstelle WV'
                                                deal={deal}
                                                participant={participant}
                                                onSubmitSuccess={onSubmitSuccess}
                                                emailTemplateSettings={emailTemplateSettings}
                                                globalMeasureDetails={globalMeasureDetails}
                                            ></GenerateWvModal>
                                        }

                                        <Button className={`btn-soft-primary me-5`} onClick={handleWvViewerModalOpen}>WV Öffnen</Button>
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <GenericInfosCard
                                title="Allgemein"
                                infos={getGeneralInfo()}
                            ></GenericInfosCard>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <Card className={`card-block card-stretch card-height`}>
                                <Card.Body>
                                    <h5 className="mb-3">Zeiten</h5>
                                    <Row className="mb-2">
                                        <Col lg={4}>
                                            <p className="font-weight-bold">Zeitraum</p>
                                        </Col>
                                        <Col>
                                            <div className="d-flex flex-column mb-2">
                                                <div style={{ width: '100%' }} className='mb-2'>
                                                    <ProgressBarComponent now={progress || 0} label='' />
                                                </div>
                                                <div className='d-flex justify-content-between text-black'>
                                                    <div >Start: {formatDateWithoutTime(deal?.start ?? '')}</div>
                                                    <div >Ende: {formatDateWithoutTime(deal?.end ?? '')}</div>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col lg={4}>
                                            <p className="font-weight-bold">Unterrichtseinheiten</p>
                                        </Col>
                                        <Col>

                                            <div className='mb-2 text-black'>
                                                <div>{deal?.ue} Gesamt</div>
                                                <div>{deal?.uePerDay} pro Tag ({getEnumValue(TimeModelEnum, deal?.timeModel)})</div>
                                                <div>{deal?.durationWeeks} Wochen; {deal?.durationMonths} Monate</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <GenericInfosCard
                                title="Finanzen"
                                infos={getFinanceInfo()}
                            ></GenericInfosCard>
                        </Col>
                    </Row>

                    <Row>
                        {deal?.externalTest === 1 && <Col lg={6}>
                            <Card className={`card-block card-stretch card-height`}>
                                <Card.Body>
                                    <h5 className="mb-3">Prüfung</h5>
                                    <p>Externe Prüfung</p>
                                    {deal?.externalTest === 1 && deal.externalWhen && deal.externalWhere && (
                                        <p>{`Am ${formatDateWithoutTime(deal.externalWhen)} in ${deal.externalWhere}`}</p>
                                    )}
                                    {deal?.externalTest === 1 && (
                                        <p>{deal.externalReg === 1 ? 'Teilnehmer bereits registriert' : ''}</p>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>}

                        <Col lg={6}>
                            <Card className={`card-block card-stretch card-height`}>
                                <Card.Body>
                                    <h5 className="mb-3">Module</h5>
                                    {deal?.listOfReleases ? <ModuleList modules={JSON.parse(deal?.listOfReleases ?? '') as any} /> : <p>-</p>}
                                </Card.Body>
                            </Card>
                        </Col>
                        {Number(deal?.status) === 0 && <Col lg={6}>
                            <GenericInfosCard
                                title="Abbruch"
                                infos={getAbortInfo()}
                                firstColumnSize={4}
                            />
                        </Col>}

                    </Row>
                    {(Number(deal?.status) === 2 || Number(deal?.status) === 3 || Number(deal?.status) === 0) && deal &&
                        <DealManagement
                            status={deal?.status}
                            cancelDate={deal?.abortDate}
                            cancelReason={deal?.abortReason}
                            onSubmitSuccess={onSubmitSuccess}
                            deal={deal}
                        />
                    }
                </Card.Body>
            </Card>
        </>);
};

export default DealInfos;