import React, { ChangeEvent, FormEvent, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Spinner,
} from 'react-bootstrap';

import { ApiClient } from '../../../services/ApiClient';
import { Deal } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { SelectOption } from '../../form/SelectWithSearch';
import { AbortReasonOptions, PermissionsEnum } from '../../../utils/enum';
import { usePermissions } from '../../../hooks/usePermissions';

type DealAbortModalProps = {
    modalTitle: string;
    buttonName: string;
    deal?: Deal;
    onSubmitSuccess: (dealId: number) => void;
};

interface FormValues {
    abortDate: string
    abortReason: string
    abortNotice: string
}

const DealAbortModal: React.FC<
    DealAbortModalProps
> = ({ modalTitle, buttonName, deal, onSubmitSuccess }) => {
    const { show, message, error, showToast, hideToast } = useToast();
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { userHasPermissionByRight } = usePermissions();

    const [formValues, setFormValues] = useState({
        abortDate: deal?.abortDate ?? '0000-00-00 00:00:00',
        abortReason: deal?.abortReason ?? '',
        abort: 1,
        abortNotice: ''
    });

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                let response;
                response = await ApiClient.put(`/deals/${deal?.id}`, formValues);
                const dealsId = response.data.id;
                onSubmitSuccess(dealsId);
                showToast('Erfolgreich gespeichert', false);
            } catch (error) {
                showToast('Fehler beim Speichern', true);
            } finally {
                setIsLoading(false);
                handleClose();
                setValidated(false);
            }
        }
    };

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { id, value } = e.target;
        setFormValues((prev: any) => ({ ...prev, [id]: value }));
    };

    const createFormGroup = (
        id: keyof FormValues,
        label: string,
        type = 'text',
        required = false,
        infoLabel: string = '',
    ) => {

        const formatDate = (dateStr: string = "") => {
            if (type === 'date') {
                return dateStr.split(' ')[0];
            }
            return dateStr;
        };

        return (
            <Form.Group className="mb-3 w-100" controlId={id.toString()}>
                <Form.Label style={{ color: 'black' }}>
                    {label} {infoLabel && <small>{infoLabel}</small>}
                </Form.Label>
                {type === 'textarea' ? (
                    <Form.Control
                        as="textarea"
                        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
                        value={formValues[id] || ''}
                        onChange={handleInputChange}
                        required={required}
                        isInvalid={validated && !formValues[id]}
                        rows={6}
                    />
                ) : (
                    <Form.Control
                        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
                        type={type}
                        value={type !== 'file' ? (type === 'date' ? formatDate(formValues[id]?.toString()) : formValues[id]) : ''}
                        onChange={handleInputChange}
                        required={required}
                        isInvalid={validated && !formValues[id]}
                    />
                )}
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
            </Form.Group>
        );
    };

    const createSelectGroup = (
        id: keyof FormValues,
        label: string,
        options: SelectOption[],
        placeholder?: string,
        required = false
    ) => (
        <Form.Group
            style={{ color: 'black' }}
            className="mb-3 w-100"
            controlId={id}
        >
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Select
                value={formValues[id]}
                onChange={handleInputChange}
                required={required}
                style={{ backgroundColor: '#F9F9F9', color: 'black' }}
            >
                {placeholder && <option value="">{placeholder}</option>}
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );

    return (
        <>
            <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewDeals,'write')} variant="danger" onClick={handleShow}>
                {buttonName}
            </Button>

            <Modal
                centered
                size="lg"
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                fullscreen='lg-down'
            >
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            {' '}
                            <h4>{modalTitle}</h4>

                        </Modal.Title>
                        <p>Der Deal kann nachträglich wieder fortgesetzt werden.</p>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>

                        <Row>
                            <Col>
                                {createFormGroup('abortDate', 'Datum des Abbruchs', 'date', true)}
                                {createSelectGroup(
                                    'abortReason',
                                    'Grund des Abbruchs',
                                    Object.entries(AbortReasonOptions).map(([value, label]) => ({
                                        value,
                                        label,
                                    })),
                                    'Auswählen...',
                                    true
                                )}
                                {createFormGroup('abortNotice', 'Weitere Notizen / Hinweise zum Abbruch', 'textarea', true)}
                            </Col>
                        </Row>

                        <div className='d-flex justify-content-end'>
                            <Button className='btn-soft-primary me-3' onClick={handleClose}>
                                Nicht jetzt
                            </Button>

                            <Button
                                disabled={isLoading}
                                variant="danger"
                                type="submit"
                            >
                                Deal abbrechen
                                {isLoading && (
                                    <Spinner
                                        className="ms-2"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></Spinner>
                                )}
                            </Button>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default DealAbortModal;
