import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { Webhook } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { useParams } from 'react-router-dom';
import { SelectOption } from '../../form/SelectWithSearch';
import { PermissionsEnum, WebhookModuleEnum, WebhookStatus, WebhookStatusEnum, } from '../../../utils/enum';
import useCompanyNavigate from '../../../hooks/useCompanyNavigate';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import DeleteModal from '../../DeleteModal';
import { usePermissions } from '../../../hooks/usePermissions';

type AddEditWebhookModalProps = {
  modalTitle: string;
  buttonName: string;
  webhook?: Webhook;
  onSubmitSuccess: (webhookId: number) => void;
};

interface FormValues {
  title: string;
  method: string;
  module: string
  status: WebhookStatus
  description: string
  code: string
}

const AddEditWebhookModal: React.FC<AddEditWebhookModalProps> = ({
  modalTitle,
  buttonName,
  webhook,
  onSubmitSuccess,
}) => {
  const { show, message, error, showToast, hideToast } = useToast();
  const { webhookId } = useParams<{ webhookId?: string }>();
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const companyNavigate = useCompanyNavigate()
  const { userHasPermissionByRight } = usePermissions();
  const [formValues, setFormValues] = useState({
    title: webhook?.title ?? '',
    method: webhook?.method ?? '',
    module: webhook?.module ?? '',
    status: webhook?.status ?? 0,
    description: webhook?.description ?? '',
    code: webhook?.code ?? '',
  });

  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...formValues,
  });

  useEffect(() => {
    if (!showModal) {
      setFormValues(initialFormValues as any);
    }
  }, [showModal]);

  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        let response;

        if (webhookId) {
          response = await ApiClient.put(`/webhooks/${webhookId}`, formValues);
        } else {
          response = await ApiClient.post('/webhooks', formValues);
        }
        const id = response.data.id;
        onSubmitSuccess(id);
        setFormValues(response.data);
        setInitialFormValues(response.data);
        showToast('Erfolgreich gespeichert', false);
      } catch (error) {
        showToast('Fehler beim Speichern', true);
      } finally {
        setIsLoading(false);
        handleClose();
        setValidated(false);
      }
    }
  };


  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormValues((prev: any) => ({ ...prev, [id]: value }));
  };

  const createFormGroup = (
    id: keyof FormValues,
    label: string,
    type = 'text',
    required = false,
    disabled = false
  ) => (
    <Form.Group className="mb-3 w-100" controlId={id}>
      {label && <Form.Label>{label}</Form.Label>}

      {type === 'textarea' ? (
        <Form.Control
          as="textarea"
          rows={5}
          type={type}
          value={formValues[id]}
          onChange={handleInputChange}
          required={required}
          className='text-black bg-grey'
          disabled={disabled}
        />
      ) : (
        <Form.Control
          type={type}
          value={type !== 'file' ? formValues[id] : ''}
          onChange={handleInputChange}
          required={required}
          className='text-black bg-grey'
          disabled={disabled}
        />
      )}

      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
    </Form.Group>
  );

  const createSelectGroup = (
    id: keyof FormValues,
    label: string,
    options: SelectOption[],
    placeholder?: string,
    required = false
  ) => (
    <Form.Group
      style={{ color: 'black' }}
      className="mb-3 w-100"
      controlId={id}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        value={formValues[id]}
        onChange={handleInputChange}
        required={required}
        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );

  const handleDeleteItem = (message: string, isError: boolean) => {
    showToast(message, isError)
    handleClose()
    companyNavigate('/webhooks')
  }


  return (
    <>
      <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewWebhooks, 'write')} variant="primary" onClick={handleShow}>
        {buttonName}
      </Button>

      <Modal
        centered
        size="xl"
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fullscreen='xl-down'
      >
        {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
            {!checkIfDataChanged() ? (
              <span>Keine Änderungen</span>
            ) : (
              <span className="text-danger">Änderungen</span>
            )}
          </div>
          <div className="d-flex">
            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button"
                style={{ padding: 0 }}
              >
                <span className="visually-hidden">More options</span>
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="div" onClick={handleClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                {webhook && <DeleteModal
                  modalTitle="Löschen?"
                  buttonName="Löschen"
                  entityIds={[webhook?.id!]}
                  entityType='webhooks'
                  onSubmit={handleDeleteItem}
                  onDeleteModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewWebhooks, 'delete')}>
                </DeleteModal>}

              </Dropdown.Menu>
            </Dropdown>
            <Button
              disabled={isLoading}
              variant="primary"
              onClick={() => hiddenSubmitButtonRef.current?.click()}
            >
              Fertig
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={submit}>
            <>
              <Row>
                <Col>
                  {createFormGroup('title', 'Titel', 'text',)}
                  {createSelectGroup(
                    'status',
                    'Status',
                    Object.entries(WebhookStatusEnum).map(([value, label]) => ({
                      value,
                      label,
                    })),
                    'Auswählen...',

                  )}
                  {createFormGroup('method', 'Methode', 'text',)}
                </Col>
                <Col>
                  {createSelectGroup(
                    'module',
                    'Modul',
                    Object.entries(WebhookModuleEnum).map(([value, label]) => ({
                      value,
                      label,
                    })),
                    'Auswählen...',

                  )}
                  {createFormGroup('description', 'Beschreibung', 'text',)}
                  {webhook && createFormGroup('code', 'Code', 'text', false, true)}
                </Col>
              </Row>
            </>
            <Button
              type="submit"
              style={{ display: 'none' }}
              ref={hiddenSubmitButtonRef}
            ></Button>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default AddEditWebhookModal;
