import React, { useState } from 'react';
import { Row, Col, Card, Button, Form, Spinner } from 'react-bootstrap';

import { Deal, } from '../../../interfaces';
import SkeletonCard from '../../skeleton/SkeletonCard';
import GenericInfosCard from '../../GenericInfosCard';
import { formatDateWithoutTime, formatEuro } from '../../../utils/utils';
import { AbortReasonOptions, DealStatusEnum, PSM, ProcessOptions1, ProcessOptions2, SelfPay, TimeModelEnum, YesNoToJaNein, getEnumValue } from '../../../utils/enum';
import AddEditDealModal from '../modal/AddEditDealModal';
import ModuleList from '../../ModuleList';
import { useToast } from '../../../services/context/ToastContext';
import { ApiClient } from '../../../services/ApiClient';
import JsonViewer from '../../JsonViewer';

interface DealDetailsProps {
    isLoading?: boolean;
    deal?: Deal | null
    onSubmitSuccess: (dealId: number) => void;
}

const DealDetails: React.FC<DealDetailsProps> = ({
    isLoading,
    deal,
    onSubmitSuccess
}) => {
    const { showToast } = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formValues, setFormValues] = useState({
        doProcess: '',
    });

    const handleInputChange = (e: any) => {
        const { value } = e.target;
        setFormValues({
            doProcess: value,
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!deal) return;

        setIsSubmitting(true);

        try {
            await ApiClient.put(`/deals/${deal.id}`, { ...deal, doProcess: formValues.doProcess });
            showToast('Erfolgreich gespeichert', false);
        } catch (error) {
            showToast('Fehler beim Speichern', true);
        } finally {
            setIsSubmitting(false);
        }
    };

    function getGeneralInfo() {
        const generalInfo = [
            {
                label: 'Status',
                value: getEnumValue(DealStatusEnum, deal?.status.toString()),
            },
            {
                label: 'BGS-Nummber',
                value: deal?.bgsnumber.toString() || '-',
            },
            {
                label: 'Hubspot - Deal - ID',
                value: deal?.hubspotDealIdLink.toString() || '-',
            },
            {
                label: 'Angebot/Rechnung, Nummer',
                value: deal?.offerNumber.toString() || '-',
            },
            {
                label: 'Angebot/Rechnung, Datum',
                value: deal?.offerDate ? formatDateWithoutTime(deal?.offerDate) : '-',
            },
            {
                label: 'Angebot/Rechnung, Titel',
                value: deal?.offerTitle || '-',
            },
            {
                label: 'Maßnahmennummer',
                value: deal?.actionNumber.toString() || '-',
            },
            {
                label: 'Macbook erhalten?',
                value: getEnumValue(YesNoToJaNein, deal?.macbook.toString()),
            },
            {
                label: 'Link zu JAMF',
                value: deal?.jamf.toString() || '-',
            },
            {
                label: 'Setter Name',
                value: deal?.setterName || '-',
            },
            {
                label: 'Closer Name',
                value: deal?.closerName || '-',
            },
        ];
        return generalInfo;
    }

    function getTimeInfo() {
        const timeInfo = [
            {
                label: 'Startdatum, offiziell',
                value: deal?.start ? formatDateWithoutTime(deal?.start) : '-',
            },
            {
                label: 'Enddatum, offiziell',
                value: deal?.end ? formatDateWithoutTime(deal?.end) : '-',
            },
            {
                label: 'Unterrichtseinheiten, gesamt',
                value: deal?.ue.toString() || '-',
            },
            {
                label: 'Unterrichtseinheiten pro Tag',
                value: deal?.uePerDay.toString() || '-',
            },
            {
                label: 'Unterrichtszeit in Wochen',
                value: deal?.durationWeeks.toString() || '-',
            },
            {
                label: 'Unterrichtszeit in Monaten',
                value: deal?.durationMonths.toString() || '-',
            },
            {
                label: 'Unterrichtsart',
                value: getEnumValue(TimeModelEnum, deal?.timeModel),
            },
        ];
        return timeInfo;
    }

    function getFinanceInfo() {
        const financeInfo = [
            {
                label: 'Preis, Brutto',
                value: deal?.priceTotalGross ? formatEuro(deal?.priceTotalGross) : '-',
            },
            {
                label: 'Preis, Netto',
                value: deal?.priceTotalNet ? formatEuro(deal?.priceTotalNet) : '-',
            },
            {
                label: 'Preis, Steuern',
                value: deal?.priceTax ? formatEuro(deal?.priceTax) : '-',
            },
            {
                label: 'Selbstzahler',
                value: getEnumValue(SelfPay, deal?.selfPay.toString()),
            },
            {
                label: 'PSM',
                value: getEnumValue(PSM, deal?.psmIncludet.toString()),
            },
        ];
        return financeInfo;
    }

    function getExternalInfo() {
        const externalInfo = [
            {
                label: 'Findet eine Externe Prüfung statt?',
                value: getEnumValue(YesNoToJaNein, deal?.externalTest.toString()),
            },
            {
                label: 'Wann findet die statt?',
                value: deal?.externalWhen ? formatDateWithoutTime(deal?.externalWhen) : '-',
            },
            {
                label: 'Wo wird sie abgehalten?',
                value: deal?.externalWhere || '-',
            },
            {
                label: 'Teilnehmer bereits registriert?',
                value: getEnumValue(YesNoToJaNein, deal?.externalReg.toString()),
            },
        ];
        return externalInfo;
    }

    function getAbortInfo() {
        const abortInfo = [
            {
                label: 'Deal abgebrochen?',
                value: getEnumValue(YesNoToJaNein, deal?.abort.toString()),
            },
            {
                label: 'Datum des Abbruchs',
                value: deal?.abortDate ? formatDateWithoutTime(deal?.abortDate) : '-',
            },
            {
                label: 'Grund des Abbruchs',
                value: getEnumValue(AbortReasonOptions, deal?.abortReason.toString()),
            },
            {
                label: 'Weitere Notizen / Hinweise zum Abbruch',
                value: deal?.abortNotice.toString() || '-',
            },
        ];
        return abortInfo;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{deal?.title}</h4>
                {deal && <AddEditDealModal
                    buttonName="Bearbeiten"
                    modalTitle={`${deal.title} bearbeiten`}
                    deal={deal}
                    onSubmitSuccess={onSubmitSuccess}
                >
                </AddEditDealModal>}
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Allgemeines"
                            infos={getGeneralInfo()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title="Zeiten"
                            infos={getTimeInfo()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title="Finanzen"
                            infos={getFinanceInfo()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title="Prüfung"
                            infos={getExternalInfo()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title="Abbruch"
                            infos={getAbortInfo()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    <Card className={`card-block card-stretch card-height`}>
                        <Card.Body>
                            <h5 className="mb-3">Module</h5>
                            {deal?.listOfReleases ? <ModuleList modules={JSON.parse(deal?.listOfReleases ?? '') as any} /> : <p>-</p>}
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card className="card-block card-stretch card-height h-100">
                        <Card.Body className="d-flex flex-column">
                            <h5 className="mb-3">JSON-Datensatz vom Webhook</h5>
                            <div className="flex-grow-1 d-flex">
                                {deal?.webhookNewDeal && (
                                    <JsonViewer data={deal?.webhookNewDeal} />
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card className={`card-block card-stretch card-height`}>
                        <Card.Body>
                            <h5 className="mb-3">Prozesse</h5>

                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group
                                    className="mb-3 w-100 text-black"
                                    controlId="doProcess"
                                >
                                    <Form.Label>Prozess initialisieren</Form.Label>
                                    <Form.Select
                                        name="doProcess"
                                        value={formValues.doProcess}
                                        onChange={handleInputChange}
                                        className='bg-grey text-black'
                                    >
                                        <option value="">Auswählen...</option>
                                        {Object.entries(ProcessOptions1).map(([value, label], index) => (
                                            <option key={index} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group
                                    className="mb-3 w-100 text-black"
                                    controlId="doProcess"
                                >
                                    <Form.Label>Prozess initialisieren (Neue Lernwelt)</Form.Label>
                                    <Form.Select
                                        name="doProcess"
                                        value={formValues.doProcess}
                                        onChange={handleInputChange}
                                        className='bg-grey text-black'
                                    >
                                        <option value="">Auswählen...</option>
                                        {Object.entries(ProcessOptions2).map(([value, label], index) => (
                                            <option key={index} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <div className='d-flex justify-content-end'>
                                    <Button
                                        type='submit'
                                        onClick={handleSubmit}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <Spinner as="span" animation="border" size="sm" /> : 'Speichern'}
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default DealDetails;
