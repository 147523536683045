import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { ApiKey } from '../../../interfaces';

import { ApiKeyStatusEnum, getEnumValue, moduleTranslations } from '../../../utils/enum';
import AddEditApiKeyModal from '../modal/AddEditApiKeyModal';
import { formatApiKey, formatDate } from '../../../utils/utils';
import { Permissions, } from '../../../interfaces';
import { getModuleIcon } from '../../../utils/roles/utils';
import LogAccordion from '../../LogAccordion';
import Card from '../../bootstrap/card';
import GenericInfosCard from '../../GenericInfosCard';
import SkeletonCard from '../../skeleton/SkeletonCard';

interface ApiKeyDetailsProps {
    isLoading?: boolean;
    apiKey?: ApiKey | null
    onSubmitSuccess: (apiKeyId: number) => void;
}

const ApiKeyDetails: React.FC<ApiKeyDetailsProps> = ({
    isLoading,
    apiKey,
    onSubmitSuccess
}) => {
    const moduleList = apiKey?.modules ?? [];

    function getInfo1() {
        const info1 = [
            {
                label: 'Titel/API-Schlüssel',
                value: apiKey?.title || '-',
            },
            {
                label: 'Beschreibung',
                value: apiKey?.description || '-',
            },
            {
                label: 'Ablaufdatum',
                value: apiKey?.expiration ? formatDate(apiKey?.expiration, 'd.m.Y H:i:s') : '-',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Status',
                value: getEnumValue(ApiKeyStatusEnum, apiKey?.status.toString()),
            },
            {
                label: 'Origin-Header(s)',
                value: apiKey?.origins || '-',
            },
            {
                label: 'Module',
                value: apiKey?.modules ? <ul className='list-no-dots'>
                    {Array.isArray(moduleList) && moduleList.length > 0
                        ? moduleList.map((item, index) => <li key={index}> {getModuleIcon(item as keyof Permissions)} {getEnumValue(moduleTranslations, item)} </li>)
                        : <li>Keine Module zugewiesen</li>}
                </ul> : '-',
                isMultiline: true
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{formatApiKey(apiKey?.title!)}</h4>
                {apiKey && <AddEditApiKeyModal
                    buttonName="Bearbeiten"
                    modalTitle={`${formatApiKey(apiKey.title)} bearbeiten`}
                    apiKey={apiKey}
                    onSubmitSuccess={onSubmitSuccess}
                >
                </AddEditApiKeyModal>
                }
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>

            </Row>
            <Row>
                <Col lg={12}>
                    <Card>

                        <Card.Body>
                            <h5 className="mb-3">Aktivitäten</h5>
                            {apiKey && apiKey.logs.length > 0 ? (
                                <LogAccordion logs={apiKey?.logs} />
                            ) : (
                                <div>Keine Aktivitäten gefunden</div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ApiKeyDetails;
